import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "../../assests/images/delete.png";
import { obituariesImagePreviewer } from "../../utils/helpers/helper";

const convertNestedObjectToArray = (nestedObj: any) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

interface FilePreviewProps {
  filesList: any[];
  isDelete?: boolean;
  profile?: boolean;
  updateFilesCb?: any;
  height?: string;
  slider?: boolean;
}
const FilePreview = (props: FilePreviewProps) => {
  const { filesList, isDelete, profile, updateFilesCb } = props;

  const [files, setFiles] = useState(filesList);

  useEffect(() => {
    setFiles(filesList);
    return () => {};
  }, [filesList]);

  const callUpdateFilesCb = (files: any) => {
    const filesAsArray = convertNestedObjectToArray(filesList);
    if (profile) {
      if (files.file) {
        var file, img;
        var _URL = window.URL || window.webkitURL;
        if ((file = files.file)) {
          img = new Image();
          var objectUrl = _URL.createObjectURL(file);
          img.onload = function () {
            _URL.revokeObjectURL(objectUrl);
          };
          img.src = objectUrl;
        }
      }
    }
    updateFilesCb(filesAsArray);
  };

  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <Grid container direction="row" columnSpacing={2} rowSpacing={2} my={2}>
      {Object.keys(files).map((fileName: any, index: number) => {
        let file = files[fileName];
        let isImage = file.type.split("/")[0];
        let path = URL.createObjectURL(file);

        return (
          <Grid
            item
            xs={12}
            md={6}
            key={`${fileName}_${index}`}
            sx={{ position: "relative" }}
          >
            {obituariesImagePreviewer(isImage, path, index, index + 1)}

            {isDelete && (
              <img
                src={DeleteIcon}
                height={"40px"}
                width={"40px"}
                alt={`Delete icon ${index}`}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "6px",
                  top: "25px",
                }}
                onClick={() => removeFile(fileName)}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FilePreview;
