import { Avatar, Box, Button, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { stripeKey } from "../../../utils/services/interceptor";
import { paymentOrder } from "../../../utils/services/service";
import { Loader } from "../../../widgets/loader";

import {
  addMemoriesInitialState,
  obituaryPostPaymentIntialState,
} from "../create/type";
import CheckoutForm from "./checkout-form";
const useStyles = makeStyles({
  headingLabel: {
    color: "#000",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "uppercase",
    lineHeight: "140%",
  },
  cardMethod: {
    width: "100%",
    height: "30px",
    textAlign: "right",
  },
  paymentDetailContainer: {
    "@media (max-width: 768px)": {
      paddingTop: "20px !important",
    },
  },
  paymentDetail: {
    marginTop: "20px !important",
    border: "1px solid #6C59B7",
    padding: "16px",
    borderRadius: "8px",
  },

  payContainer: {
    padding: "20px 10px",
    background: "linear-gradient(273.53deg, #9088C5 2.52%, #51438A 95.45%)",
    borderRadius: "16px 16px 0px 0px",
  },
});

const stripePromise = loadStripe(`${stripeKey}`);
interface RefObject {
  log: () => void;
}

const Label = (props: any) => {
  const { xs, md, fontWeight, fontSize, uppercase, children, value } = props;
  return (
    <Grid
      item
      xs={xs}
      md={md}
      sx={{
        fontFamily: "Lato !important",
        fontWeight: fontWeight ? fontWeight : 400,
        fontSize: fontSize ? fontSize : "16px !important",
        lineHeight: "140% !important",
        color: "#000 !important",
        textTransform: uppercase ? "uppercase" : "",
        textAlign: children ? "" : "center",
      }}
    >
      {value ? `$${value.toFixed(2)}` : children ? children : "$0.00"}
    </Grid>
  );
};

const StripePayment = (props: any) => {
  const classes = useStyles();

  const { obituaryData, handleChange, mode, setMode, type } = props;
  const ref = useRef<RefObject>(null);

  const [obituaryDetails, setObituaryDetails] = useState(
    obituaryPostPaymentIntialState
  );
  const [addMemoriesPayment, setAddMemoriesPayment] = useState(
    addMemoriesInitialState
  );
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [addMemoriesModal, setAddMemoriesModal] = useState(false);

  const [error, setError] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const { obituaryId } = useParams();
  const publisherId = addMemoriesPayment.publisher.id;
  const publisherName = `${addMemoriesPayment.creator.firstName} ${addMemoriesPayment.creator.lastName}`;
  const isFlatDiscount =
    obituaryDetails.isFlatDiscount || addMemoriesPayment.isFlatDiscount;
  const isFreeObituary = obituaryDetails.contentPriceDetails.totalCharge === 0;

  const obituaryName = obituaryDetails?.name || addMemoriesPayment?.name;
  const obituaryProfile =
    obituaryDetails?.profile || addMemoriesPayment?.profile;
  
  const pathName = window.location?.pathname

  useEffect(() => {
    window.location.hash = "no-back";
    window.onhashchange = function () {
      window.location.hash = "no-back";
    };

    if (!type && obituaryData && obituaryData.paymentInfo.id) {
      setObituaryDetails({
        ...obituaryDetails,
        name: `${obituaryData?.data?.firstName} ${obituaryData?.data?.lastName}`,
        profile: URL.createObjectURL(obituaryData?.profileImg[0]),
        deceased: obituaryData.paymentInfo.deceased,
        contentPriceDetails: {
          ...obituaryData.paymentInfo.contentPriceDetails,
          originalTotalCharge:
            obituaryData.paymentInfo.contentPriceDetails.totalCharge,
        },
      });
    } else if (localStorage.getItem("paymentSession")) {
      let data: any = JSON.parse(localStorage.getItem("paymentSession") || "");
      setAddMemoriesPayment({
        ...data,
        name: data?.name,
        profile: data?.profile,
        charge: {
          ...data.charge,
          originalTotalCharge: data.charge.totalCharge,
        },
      });
      setObituaryDetails({
        ...obituaryDetails,
        contentPriceDetails: {
          ...data.charge,
          originalTotalCharge: data.charge.totalCharge,
        },
      });
    } else {
      navigate("/obituary-create");
    }

    return () => {};
  }, [obituaryData, type]);

  const makePayment = async (token: any, accountInfo: any) => {
    const body = {
      token,
      product: {
        name: "Obituary Entry",
        price:
          addMemoriesPayment.charge.totalCharge ||
          obituaryData.paymentInfo.contentPriceDetails.totalCharge,
      },
      creatorId: addMemoriesPayment.id || obituaryData.paymentInfo.id,
      obituary_id: obituaryId || obituaryData.paymentInfo.deceased.id,
      role: obituaryId ? "CREATOR" : "CONTRIBUTOR",
      email: accountInfo.email,
      description: pathName.includes("addMemories") ? "Add Memories" : "Obituary Entry",
      amount:
        addMemoriesPayment.charge.totalCharge ||
        obituaryDetails.contentPriceDetails.totalCharge,
      zip_code: accountInfo.zipCode,
      coupon: obituaryDetails.coupon || addMemoriesPayment.coupon,
      discountAmount:
        obituaryDetails.discountAmount || addMemoriesPayment.discountAmount,
      nameOnCard: accountInfo.cardName,
    };

    setLoader(true);
    try {
      const response: any = await paymentOrder(body);
      setLoader(false);
      if (response.code === 200 && type === "") {
        localStorage.removeItem("paymentSession");
        setError(response.message);
        setPaymentDone(true);
        navigate(
          `/payment-success/${
            obituaryId || obituaryData.paymentInfo.deceased.id
          }/${obituaryData.paymentInfo.id}/create`
        );
        sessionStorage.setItem("paymentSession", "success");
      } else if (response.code === 200 && type === "paymentByPass") {
        //setAddMemoriesModal(true);
        localStorage.removeItem("paymentSession");
        sessionStorage.setItem("paymentSession", "success");
        navigate(
          `/payment-success/${obituaryId}/${publisherId}/${publisherName}`
        );
      } else {
        setPaymentDone(false);
        //setOpenModal(true);
        setError(response.message);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handlePaymentByPass = async () => {
    let couponBody = {
      creatorId: addMemoriesPayment.id || obituaryData.paymentInfo.id,
      obituary_id: obituaryId || obituaryData.paymentInfo.deceased.id,
      amount:
        addMemoriesPayment.charge.totalCharge ||
        obituaryDetails.contentPriceDetails.totalCharge,
      coupon:
        obituaryDetails.coupon ||
        addMemoriesPayment.coupon ||
        "$free_obituary@2023",
    };
    try {
      const response: any = await paymentOrder(couponBody);

      if (response.code === 200) {
        localStorage.removeItem("paymentSession");

        setPaymentDone(true);
        setOpenModal(true);
      } else {
        setPaymentDone(false);
        setOpenModal(true);
        setError(response.message);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  const handleCuopon = useCallback(
    (discount: any) => {
      if (type) {
        setAddMemoriesPayment({
          ...addMemoriesPayment,
          charge: {
            ...addMemoriesPayment.charge,
            totalCharge: discount.totalAmount,
          },

          coupon: discount.couponCode,
          discount: discount.discount,
          isFlatDiscount: discount.isFlatDiscount,
        });
      }
      setObituaryDetails({
        ...obituaryDetails,
        discountAmount: discount.discountAmount,
        discount: discount.discount,
        contentPriceDetails: {
          ...obituaryDetails.contentPriceDetails,
          totalCharge: discount.totalAmount,
        },
        coupon: discount.couponCode,
        isFlatDiscount: discount.isFlatDiscount,
      });
      if (!discount.totalAmount) {
        setMode({
          ...mode,
          isCoupon: true,
        });
      } else {
        setMode({
          ...mode,
          isCoupon: false,
        });
      }
    },
    [addMemoriesPayment, obituaryDetails]
  );

  const divider = (
    <Divider variant={"fullWidth"} sx={{ width: "100%", marginTop: "10px" }} />
  );

  return (
    <>
      <Container maxWidth="lg">
        <Grid container direction="row" columnSpacing={{ md: 3 }} pt={2}>
          <Grid item xs={12} md={6} p={{ xs: 0, md: 2 }}>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                xs={12}
                md={12}
                className={classes.headingLabel}
                mb={1}
              >
                Secure Payment
              </Grid>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  makePayment={makePayment}
                  amount={obituaryDetails.publisherRequest.charge}
                  ref={ref}
                  setError={setError}
                />
                {!paymentDone && error && (
                  <Grid
                    item
                    xs={12}
                    className="main-text"
                    sx={{ color: "#E32C2B" }}
                    pt={2}
                  >
                    {error}
                  </Grid>
                )}
              </Elements>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} my={{ xs: 3, md: 0 }}>
            <Box
              bgcolor="#FFF"
              height={{ xs: "auto", md: "95%" }}
              p={2}
              borderRadius={1}
            >
              <Grid item xs={12} className={classes.headingLabel}>
                Payment Details
              </Grid>
              <Grid
                container
                alignItems="center"
                rowSpacing={1}
                px={{ xs: 0, md: 2 }}
              >
                <Grid
                  item
                  xs={9}
                  md={8}
                  my={2}
                  display="flex"
                  alignItems="center"
                  className="main-text"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Avatar
                    alt={obituaryName || "obituaryName"}
                    src={obituaryProfile}
                    variant="square"
                    sx={{
                      width: 70,
                      height: 70,
                      borderRadius: "10px",
                      boxShadow: 4,
                      mr: 1.5,
                    }}
                  />
                  Obituary For
                  <Box
                    component="span"
                    sx={{
                      whiteSpace: "nowrap !important",
                      width: "calc(100%-6px) !important",
                      overflow: "hidden !important",
                      textOverflow: "ellipsis !important",
                      fontWeight: "600",
                      paddingLeft: "4px",
                    }}
                    title={obituaryName.toLowerCase()}
                  >
                    {obituaryDetails.deceased.petName}
                  </Box>
                </Grid>

                <Label
                  xs={3}
                  md={4}
                  fontWeight={600}
                  value={obituaryDetails.contentPriceDetails.basicPrice}
                />

                <Label xs={9} md={8}>
                  One Obituary
                </Label>
                <Label
                  xs={3}
                  md={4}
                  fontWeight={600}
                  value={obituaryDetails.contentPriceDetails.basicPrice}
                />

                {/* {obituaryDetails.contentPriceDetails.noOfPhotos > 0 && (
                  <>
                    <Label xs={9} md={8}>
                      {`${obituaryDetails.contentPriceDetails.noOfPhotos} Photos`}
                    </Label>
                    <Label
                      xs={3}
                      md={4}
                      fontWeight={600}
                      value={obituaryDetails.contentPriceDetails.imagesPrice}
                    />
                  </>
                )}
                {obituaryDetails.contentPriceDetails.noOfPdfPages > 0 && (
                  <>
                    <Label xs={9} md={8}>
                      {`${obituaryDetails.contentPriceDetails.noOfPdfPages} Pdfs`}
                    </Label>
                    <Label
                      xs={3}
                      md={4}
                      fontWeight={600}
                      value={obituaryDetails.contentPriceDetails.pdfPrice}
                    />
                  </>
                )}
                {obituaryDetails.contentPriceDetails.noOfVideo > 0 && (
                  <>
                    <Label xs={9} md={8}>
                      {`${obituaryDetails.contentPriceDetails.noOfVideo} Videos`}
                    </Label>
                    <Label
                      xs={3}
                      md={4}
                      fontWeight={600}
                      value={obituaryDetails.contentPriceDetails.videoPrice}
                    />
                  </>
                )} */}

                {/* {obituaryDetails.discountAmount && (
                  <>
                    {divider}
                    <Label xs={9} md={8}>
                      Sub Total :
                    </Label>
                    <Label
                      xs={3}
                      md={4}
                      fontWeight={600}
                      value={
                        obituaryDetails.contentPriceDetails.originalTotalCharge
                      }
                    />

                    <Label xs={9} md={8}>
                      {isFlatDiscount
                        ? "Flat Discount"
                        : `Discount (${obituaryDetails.discount}%)`}
                    </Label>
                    <Label
                      xs={3}
                      md={4}
                      fontWeight={600}
                      discount={true}
                      value={obituaryDetails.discountAmount}
                    />
                  </>
                )} */}

                <Label xs={9} md={8} fontWeight={600} fontSize="18px">
                  Total
                </Label>
                <Label
                  xs={3}
                  md={4}
                  fontWeight={600}
                  fontSize="18px"
                  value={obituaryDetails.contentPriceDetails.totalCharge}
                />
              </Grid>
              <Grid
                container
                justifyContent={{ xs: "center", md: "right" }}
                mt={{ xs: 4, md: 19 }}
              >
                {/* {!type && (
                  <Grid item xs={4} md={3}>
                    <Button
                      className="see-examples"
                      size="small"
                      fullWidth
                      onClick={(event) => {
                        handleChange(event, "1");
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                )} */}
                <Grid item xs={6} md={6}>
                  <Button
                    fullWidth
                    sx={{
                      textTransform: "capitalize",
                    }}
                    className="obituary"
                    onClick={() => {
                      if (mode.isCoupon) {
                        handlePaymentByPass();
                      } else {
                        if (ref.current) {
                          ref.current.log();
                        }
                      }
                    }}
                  >
                    Pay $
                    {obituaryDetails.contentPriceDetails.totalCharge.toFixed(
                      2
                    ) || 0}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {/* <Grid item xs={12}>
              <Coupon
                totalAmount={
                  obituaryDetails.contentPriceDetails.originalTotalCharge
                }
                deceasedId={obituaryId || obituaryData.paymentInfo.deceased.id}
                applyCouponCode={handleCuopon}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Container>

      {/* <ModalComponent
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
          setError("");
        }}
        closeIcon={!paymentDone}
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            <Grid
              item
              xs={12}
              md={12}
              className="h2"
              sx={{
                color: paymentDone ? "" : "#E16F6F",
              }}
            >
              {paymentDone ? "Payment Successful!" : "Something went wrong"}
            </Grid>
            <Grid item xs={12} md={12} className="main-text">
              {paymentDone
                ? "Now you can watch the video and share obituary with others."
                : "Please check your last submission for mistakes in formatting."}
            </Grid>

            {paymentDone && (
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  className="modal-button"
                  onClick={() => {
                    setOpenModal(false);
                    if (!type) {
                      navigate(
                        `/obituary-share/${
                          obituaryId || obituaryData.paymentInfo.deceased.id
                        }/${
                          addMemoriesPayment.id || obituaryData.paymentInfo.id
                        }`
                      );
                    } else {
                      //Your additions are sent! modal open and goto obituray view
                      navigate("/home");
                    }
                  }}
                >
                  Proceed
                </Button>
              </Grid>
            )}
          </Grid>
        }
      />
      <ModalComponent
        openModal={addMemoriesModal}
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} md={12}>
              <img alt="create" src={Create} className={classes.img} />
            </Grid>
            <Grid item xs={12} md={12} className="h2">
              Your additions are sent!
            </Grid>
            <Grid item xs={12} md={12} className="main-text">
              {publisherName} will receive it via email. It will be added to
              this obituary after {publisherName} confirms it.
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                className="modal-button"
                onClick={() => {
                  navigate(`/obituraies-view/${obituaryId}/${publisherId}`);

                  setAddMemoriesModal(false);
                }}
              >
                See current version of obituary
              </Button>
            </Grid>
          </Grid>
        }
      /> */}
      <Loader loader={loader} />
    </>
  );
};

export default memo(StripePayment);
