import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
const useStyles = makeStyles({
  modalStyle: {
    "& .MuiBackdrop-root": {
      backgroundColor: "#E4E4E4",
      opacity: "0.8 !important",
    },
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "-30px !important",
    top: "-30px !important",
    color: "#6C59B7",
    fontSize: "40px !important",
    "@media (max-width: 500px)": {
      right: "-20px !important",
      top: "-20px !important",
      fontSize: "30px !important",
    },
  },
});

interface ModalProps {
  openModal: boolean;
  closeIcon?: boolean;
  closeModal?: any;
  modalHeading?: string;
  content?: JSX.Element;
  modalWidth?: number;
}

export const ModalComponent = (props: ModalProps) => {
  const {
    openModal,
    closeIcon,
    closeModal,
    modalHeading,
    content,
    modalWidth,
  } = props;
  const classes = useStyles();

  const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: modalWidth || 400,
    bgcolor: "#fff",
    borderRadius: "20px",
    background: "#FFF",
    boxShadow:
      "0px 34px 14px rgba(41, 30, 67, 0.01), 0px 19px 12px rgba(41, 30, 67, 0.05), 0px 9px 9px rgba(41, 30, 67, 0.09), 0px 2px 5px rgba(41, 30, 67, 0.1), 0px 0px 0px rgba(41, 30, 67, 0.1)",
    padding: "20px !important",
    "@media (max-width: 1000px)": {
      width: modalWidth ? "calc(100% - 100px) !important" : 400,
    },
    "@media (max-width: 500px)": {
      width: "calc(100% - 90px) !important",
      padding: "10px !important",
    },
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalStyle}
    >
      <Box sx={modalBoxStyle}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {closeIcon && closeModal && (
            <Grid item xs={"auto"}>
              <CloseIcon onClick={closeModal} className={classes.closeIcon} />
            </Grid>
          )}
          <Grid item xs={12}>
            {modalHeading}
          </Grid>

          {content && (
            <Grid item xs={12}>
              {content}
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};
