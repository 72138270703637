import { createSlice } from "@reduxjs/toolkit";

export interface ObituariesState {
  obituaries: {
    data: any[];
    page: number;
    size: number;
    totalCount: number;
    pagination: any[];
  };
}

const initialState: ObituariesState = {
  obituaries: {
    data: [],
    page: 0,
    size: 9,
    totalCount: 0,
    pagination: []
  },
};

export const obituariesSlice = createSlice({
  name: "obituariesList",
  initialState,
  reducers: {
    setObituaries: (state, action) => {
      state.obituaries = action.payload;
    },
  },
});

export const { setObituaries } = obituariesSlice.actions;

export default obituariesSlice.reducer;
