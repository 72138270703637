import { InputLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";

interface DatePickerProps {
  name?: string;
  label?: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange?: any;
  nameRef?: any;
}
const DatePicker = (props: DatePickerProps) => {
  const { label, name, value, onChange, error, helperText, nameRef } = props;
  return (
    <>
      <InputLabel
        sx={{
          fontSize: "16px",
          color: "#23222799",
          fontWeight: 400,
          fontFamily: "Lato",
          paddingBottom: "8px",
        }}
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={dayjs(value)}
          inputRef={nameRef}
          inputFormat="MM/DD/YYYY"
          minDate={dayjs("1900-01-01")}
          views={["year", "month", "day"]}
          disableFuture
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              fullWidth
              error={error}
              helperText={(error && helperText) || ""}
              sx={{
                "& .MuiFormHelperText-root": { marginLeft: "2px !important" },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
export default DatePicker;
