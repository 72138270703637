import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { regEmail } from "../../utils/helpers/regEx";
import EmailInput from "../emailInput";

const useStyles = makeStyles({
  shareLabel: {
    color: "rgba(35, 34, 39, 0.6)",
    textAlign: "center",
  },
  chip: {
    margin: "2px !important",
    borderColor: "#6C59B7 !important",
  },
});
interface AddEmailProps {
  addEmails: (emailList: string[]) => void;
  onclickShare?: any;
}
const AddEmail = (props: AddEmailProps) => {
  const { addEmails, onclickShare } = props;
  const classes = useStyles();

  const [emailList, setEmailList] = useState<string[]>([]);
  const [email, setEamil] = useState("");
  const [isValidate, setIsValidate] = useState(false);

  const handleAddEmail = (event: any) => {
    event.preventDefault();

    if (email.trim() === "" || isValidate) {
      setIsValidate(true);
      return true;
    }
    const index = emailList.findIndex((emailList) => emailList === email);

    if (index === -1) {
      setEamil("");
      setEmailList([...emailList, email]);
    }
  };

  useEffect(() => {
    addEmails(email.length ? [...emailList, email] : emailList);
  }, [emailList, email]);

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    setEamil(value);
    if (!regEmail.test(value)) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
  };

  const handleDelete = (item: string) => {
    setEmailList(emailList.filter((elem: string) => elem !== item));
  };

  const handleOnClickShare = () => {
    if (emailList.length > 0 || (email.length > 0 && !isValidate)) {
      onclickShare();
    } else {
      setIsValidate(true);
    }
  };

  return (
    <Grid
      container
      direction="row"
      rowSpacing={{ xs: 2, md: 1 }}
      columnGap={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={12}
        className="main-text"
        sx={{ textTransform: "uppercase" }}
        textAlign="center"
      >
        Add people to share the obituary
      </Grid>
      <Grid item xs={12} md={12} className={`main-text ${classes.shareLabel}`}>
        They will have an ability to add their memories, photos and videos to
        this Obituary
      </Grid>
      <Grid item xs={12} md={4}>
        <EmailInput
          name={"email"}
          label="E-mail"
          value={email}
          error={isValidate}
          placeholder="Add email "
          onChange={handleOnChange}
          onClickAddEmail={handleAddEmail}
        />
      </Grid>
      {/* <Grid
        item
        xs={12}
        md={4}
        alignSelf={isValidate ? "center" : "end"}
        mt={{ md: 1 }}
      >
        <Button
          fullWidth
          variant="contained"
          className={"obituary"}
          onClick={handleAddEmail}
        >
          Add another person to share
        </Button>
      </Grid> */}
      {onclickShare && (
        <Grid
          item
          xs={12}
          md={4}
          mt={{ xs: 0, md: 1 }}
          alignSelf={isValidate ? "center" : "end"}
        >
          <Button
            fullWidth
            variant="contained"
            className={"obituary"}
            onClick={handleOnClickShare}
          >
            Share
          </Button>
        </Grid>
      )}

      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} mt={2} px={2}>
          {emailList.map((item, index) => {
            return (
              <Chip
                variant="outlined"
                label={item}
                key={`${item}_${index}`}
                onDelete={() => {
                  handleDelete(item);
                }}
                className={`main-text ${classes.chip}`}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddEmail;
