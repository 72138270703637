import { makeStyles } from "@mui/styles";
import React, { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { obituariesImagePreviewer } from "../../utils/helpers/helper";

const useStyles = makeStyles({
  curosal: {
    "& .react-multiple-carousel__arrow": {
      backgroundColor: "#FFF !important",
      minWidth: "auto !important",
      "&:hover": {
        backgroundColor: "#FFF !important",
      },
      "&:before": {
        color: "#6C59B7 !important",
        fontSize: "25px",
        fontWeight: "600 !important",
      },
    },
    "& .react-multi-carousel-dot button": {
      backgroundColor: "#D9D9D9 !important",
      border: "0",
      padding: "7px",
    },
    "& .react-multi-carousel-dot--active button": {
      backgroundColor: "#6C59B7 !important",
    },
  },
  carouselItem: {
    marginRight: "20px",
    "&:last-child": {
      marginRight: "0 !important",
    },
    "@media (max-width: 425px)": {
      marginRight: "0 !important",
    },
  },
});

interface CarouselProps {
  sliderItems?: string[];
  children?: React.ReactNode;
  showDots?: boolean;
  swipeable?: boolean;
  infinite?: boolean;
  autoPlay?: boolean;
}

const Slider = (props: CarouselProps): JSX.Element => {
  const classes = useStyles();
  const {
    children,
    sliderItems,
    showDots,
    autoPlay,
    swipeable = true,
    infinite,
  } = props;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {children && (
        <Carousel
          responsive={responsive}
          showDots={showDots}
          autoPlay={autoPlay}
          swipeable={swipeable}
          infinite={infinite}
          partialVisible={false}
          ssr={true}
          itemClass={classes.carouselItem}
          className={classes.curosal}
        >
          {children}
        </Carousel>
      )}

      {sliderItems && (
        <Carousel
          className={classes.curosal}
          responsive={responsive}
          showDots={sliderItems.length > 2}
          autoPlay={autoPlay}
          swipeable={swipeable}
          infinite={infinite}
        >
          {sliderItems?.map((item: any, index: number) => {
            return (
              <React.Fragment key={item.id}>
                {obituariesImagePreviewer(item.type, item.path, index, item.id)}
              </React.Fragment>
            );
          })}
        </Carousel>
      )}
    </>
  );
};

export default memo(Slider);
