import ZoomInIcon from "@mui/icons-material/ZoomInSharp";
import ZoomOutIcon from "@mui/icons-material/ZoomOutSharp";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
interface ImageCroperProps {
  image: any;
  setCropImage: any;
}
const ImageCroper = (props: ImageCroperProps) => {
  const { image, setCropImage } = props;
  const editor = useRef<any>(null);
  const [scale, setScale] = useState<number>(1);

  const croppedImage = async () => {
    const dataUrl = editor?.current.getImage().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result?.blob();
    const croppedFile = new File([blob], image?.name, {
      type: image?.type,
      lastModified: Date.now(),
    });
    setCropImage([croppedFile]);
  };

  const handleZoomIn = () => {
    setScale((prevState: any) => prevState + 0.2);
  };
  const handleZoomOut = () => {
    setScale((prevState: any) => prevState - 0.2);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      rowSpacing={2}
    >
      <Grid item xs={12} md={12}>
        <AvatarEditor
          ref={editor}
          image={image}
          width={200}
          height={200}
          scale={scale}
          borderRadius={100}
          style={{
            width: "100%",
            maxWidth: "250px",
            maxHeight: "250px",
          }}
        />
      </Grid>

      <Grid item xs={4} sm={3} md={4} mr={1}>
        <ButtonGroup variant="text">
          <Button disabled={scale >= 2} onClick={handleZoomIn}>
            <ZoomInIcon sx={{ color: scale >= 2 ? "" : "#6c59b7" }} />
          </Button>
          <Button disabled={scale <= 1} onClick={handleZoomOut}>
            <ZoomOutIcon sx={{ color: scale <= 1 ? "" : "#6c59b7" }} />
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={4} sm={3} md={6}>
        <Button
          fullWidth
          variant="contained"
          className="modal-button"
          onClick={croppedImage}
        >
          Crop
        </Button>
      </Grid>
    </Grid>
  );
};

export default ImageCroper;
