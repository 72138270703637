import { useNavigate } from "react-router-dom";
import logo from "../../assests/images/Logo.svg";

interface LogoProps {
  onClick?: any;
}
const Logo = (props: LogoProps) => {
  const { onClick } = props;
  const navigate = useNavigate();

  return (
    <img
      alt="logo"
      src={logo}
      style={{
        height: "50px",
        cursor: "pointer",
        objectFit: "contain",
        textAlign: "left",
      }}
      onClick={() => {
        navigate(`/home`);
        onClick && onClick();
      }}
    />
  );
};

export default Logo;
