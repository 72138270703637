import { Container, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setObituaries } from "../../../reducers/getObituaires";
import { obituariesPagination } from "../../../utils/helpers/helper";
import {
  getObituaryDetailsService,
  getObituaryListPostService,
  shareObituaryService,
} from "../../../utils/services/service";
import AddEmail from "../../../widgets/addEmail";
import { Loader } from "../../../widgets/loader";
import { ModalComponent } from "../../../widgets/modal/modal";
import ObiturayContentView from "../../obituraiesView/view";
import {
  initalState,
  obiturayDetailInitalState,
  paymentInitialState,
} from "../create/type";

const useStyles = makeStyles({
  shareContainer: {
    padding: "20px 16px",
    background: "linear-gradient(273.53deg, #9088C5 2.52%, #51438A 95.45%)",
    borderRadius: "16px 16px 0px 0px",
  },
});

const ShareObituary = (props: any) => {
  const { setObituaryData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [shareEmails, setShareEmails] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { id, publisherRequestId } = useParams();
  const [obiturayDetail, setObiturayDetails] = useState(
    obiturayDetailInitalState
  );
  const navigate = useNavigate();
  const isDisabled = shareEmails.length === 0;

  useEffect(() => {
    const getDetails = async () => {
      let response: any = await getObituaryDetailsService(
        id,
        publisherRequestId
      );

      if (response.code === 200) {
        setObiturayDetails(response.body);
      }
    };
    getDetails();
    return () => {};
  }, [id, publisherRequestId]);

  const handleShareObituray = async () => {
    setLoader(true);
    if (shareEmails.length) {
      const response: any = await shareObituaryService(
        publisherRequestId,
        shareEmails
      );
      setLoader(false);
      if (response.code === 200) {
        setOpenModal(true);
        setObituaryData({
          data: initalState,
          profileImg: [],
          multimediaFiles: [],
          paymentInfo: paymentInitialState,
        });
      }
    } else {
      setLoader(false);
    }
  };

  const getObituaryList = async () => {
    setLoader(true);
    let response: any = await getObituaryListPostService(0);
    if (response && response.code === 200) {
      const pagination = obituariesPagination(response.body.totalCount);
      dispatch(setObituaries({ ...response.body, pagination }));
      navigate(`/obituraies-view/${id}/${publisherRequestId}`);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  return (
    <>
      <Container maxWidth="lg">
        <AddEmail
          addEmails={(emailList: string[]) => {
            setShareEmails(emailList);
          }}
          onclickShare={handleShareObituray}
        />
        <Box mt={9}>
          <ObiturayContentView obiturayDetail={obiturayDetail} />
        </Box>
      </Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        textAlign="center"
        className={classes.shareContainer}
        gap={2}
      >
        <Grid item xs={10} sm={6} md={4} lg={3}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ opacity: !isDisabled ? 0.7 : 1 }}
            disabled={!isDisabled}
            className="outline-button"
            onClick={() => {
              getObituaryList();
            }}
          >
            Go to the obituary
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={3}>
          <Button
            fullWidth
            variant="contained"
            sx={{ opacity: isDisabled ? 0.7 : 1 }}
            className="proceed-to-preview"
            onClick={handleShareObituray}
            disabled={isDisabled}
          >
            Share
          </Button>
        </Grid> */}
      </Grid>

      <ModalComponent
        openModal={openModal}
        content={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            rowSpacing={{ xs: 1, md: 2 }}
          >
            <Grid item xs={12} md={12} className="h2">
              You succesfully shared the obituary!
            </Grid>
            <Grid item xs={12} md={12} className="main-text">
              When they add some memories to this obituary, you will receive an
              email to submit new content.
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                className="modal-button"
                onClick={() => {
                  getObituaryList();
                  setOpenModal(false);
                }}
              >
                Go to the obituary page
              </Button>
            </Grid>
          </Grid>
        }
      />

      <Loader loader={loader} />
    </>
  );
};

export default memo(ShareObituary);
