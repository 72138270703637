import { Box, Button, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FileUpload, {
  mutipleFileSupport,
  profileExtension,
} from "../../../components/fileUpload";
import FilePreview from "../../../components/fileUpload/file-preview";
import {
  cityList,
  countryList,
  relationShips,
  stateList,
} from "../../../utils/helpers/constants";
import { validateEmptyFields } from "../../../utils/helpers/helper";
import AutoComplete from "../../../widgets/autoComplete";
import DatePicker from "../../../widgets/datePicker";
import EmailInput from "../../../widgets/emailInput";
import { Loader } from "../../../widgets/loader";
import TextInput from "../../../widgets/textInput";
//import { stateList } from "./state.constant";
import ImageCroper from "../../../components/imageCroper";
import { baseUrl } from "../../../utils/services/interceptor";
import { ModalComponent } from "../../../widgets/modal/modal";
import { ObituaryDto, initalState } from "./type";
const useStyles = makeStyles({
  container: {
    marginTop: "10px !important",
  },
  processToReviewContainer: {
    marginTop: "20px !important",
    padding: "20px 0",
    background: "linear-gradient(273.53deg, #9088C5 2.52%, #51438A 95.45%)",
    borderRadius: "16px 16px 0px 0px",
  },
  error: {
    color: "#E16F6F",
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    marginTop: "3px",
    marginRight: "14px",
    fontFamily: "Lato",
  },
  total: {
    color: "#fff",
    fontFamily: "Lato !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "140%",
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
    },
  },

  toolTipContainer: {
    color: "#fff",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "140%",
    padding: "16px",
  },
  processPreviewButton: {
    boxShadow: "none !important",
    color: "#232227 !important",
    backgroundColor: "#fff !important",
    fontFamily: "Lato !important",
    fontSize: "20px !important",
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
      padding: "10px !important",
    },
  },
});

const CreateObituary = (props: any) => {
  const classes = useStyles();

  const { handleChange, setObituaryData, obituaryData, value, mode } = props;

  const [createObituaryData, setCreateObituaryData] = useState<ObituaryDto>(
    initalState || obituaryData.data
  );
  const [profileImg, setProfileImg] = useState([]);
  const [openModal, setOpenModal] = useState({ open: false, image: "" });

  const [multimediaFiles, setMultimedia] = useState([]);
  const [isValidate, setIsValidate] = useState(false);
  const [statesList, setStatesList] = useState<any[]>([]);
  const [cityLists, setCityLists] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [dateValidation, setDateValidation] = useState("");
  const [error, setError] = useState("");

  const getStates = useCallback((countryName: string) => {
    const stateRes = stateList?.filter(
      (state: any) =>
        state.label.toLocaleLowerCase() === countryName.toLocaleLowerCase()
    );
    setStatesList(stateRes[0]?.state || []);
  }, []);

  const getCity = useCallback((stateName: string) => {
    const cityRes = cityList?.filter(
      (city: any) =>
        city.label.toLocaleLowerCase() === stateName.toLocaleLowerCase()
    );
    setCityLists(cityRes[0]?.city || []);
  }, []);

  useEffect(() => {
    if (obituaryData) {
      getStates(obituaryData.data.country);
      getCity(obituaryData.data.state);
      setCreateObituaryData(obituaryData.data);
      setProfileImg(obituaryData.profileImg);
      setMultimedia(obituaryData.multimediaFiles);
    }
    return () => {};
  }, [
    obituaryData.data,
    obituaryData.profileImg,
    obituaryData.multimediaFiles,
    value,
    obituaryData,
    getStates,
    getCity,
  ]);

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;

    setCreateObituaryData({
      ...createObituaryData,
      [name]: value,
    } as any);
  };

  const dateValidations = useMemo(() => {
    const currentDate = formatDate(new Date());
    let isValidDate = true;

    const error = () => {
      setDateValidation("Please Enter Valid Date");
      isValidDate = false;
    };

    if (createObituaryData.dob !== "" && createObituaryData.dod !== "") {
      if (createObituaryData.dob === createObituaryData.dod) {
        error();
      } else if (
        createObituaryData.dod > currentDate ||
        createObituaryData.dob > currentDate
      ) {
        error();
      } else if (
        createObituaryData.dob > createObituaryData.dod ||
        createObituaryData.dod < createObituaryData.dob
      ) {
        error();
      } else {
        setDateValidation("");
        isValidDate = true;
      }
    }

    return isValidDate;
  }, [createObituaryData.dob, createObituaryData.dod]);

  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleOnDateChange = (name: string, value: any) => {
    setCreateObituaryData({
      ...createObituaryData,
      [name]: dayjs(value).format("YYYY-MM-DD"),
    } as any);
  };

  const updateUploadedFiles = (files: any) => {
    setMultimedia(files);
  };

  const updateProfile = (files: any) => {
    setOpenModal({
      ...openModal,
      open: true,
      image: files[0],
    });
  };

  const removeProfile = (files: any) => {
    setProfileImg(files);
  };

  const petNameRef: any = useRef();
  const dateOfBirthRef: any = useRef();
  const dateOfDeathRef: any = useRef();
  const countryRef: any = useRef();
  const yourFirstNameRef: any = useRef();
  const yourLastNameRef: any = useRef();
  const emailRef: any = useRef();
  const descriptionRef: any = useRef();
  const stateRef: any = useRef();
  const cityRef: any = useRef();
  const profileImgRef: any = useRef();

  const handleFocus = () => {
    if (createObituaryData.petName === "") {
      petNameRef.current.focus();
    } else if (createObituaryData.dob === "") {
      dateOfBirthRef.current.focus();
    } else if (createObituaryData.dod === "") {
      dateOfDeathRef.current.focus();
    } else if (createObituaryData.country === "") {
      countryRef.current.focus();
    } else if (createObituaryData.state === "") {
      stateRef.current.focus();
    } else if (createObituaryData.city === "") {
      cityRef.current.focus();
    } else if (createObituaryData.publisherFirstName === "") {
      yourFirstNameRef.current.focus();
    } else if (createObituaryData.publisherLastName === "") {
      yourLastNameRef.current.focus();
    } else if (createObituaryData.email === "") {
      emailRef.current.focus();
    } else if (createObituaryData.description === "") {
      descriptionRef.current.focus();
    } else if (profileImg.length === 0) {
      profileImgRef.current.focus();
    }
  };
  const [progressBar, setProgressBar] = useState("");

  const createObituaryService = useCallback((data: any, update?: boolean) => {
    return baseUrl.post(`obituary/`, data, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: ({ progress }: any) => {
        setProgressBar((progress * 100).toFixed(0));
      },
    } as any);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let validateInfo: any = { ...createObituaryData };

    delete validateInfo.middleName;
    delete validateInfo.publisherRelation;

    let totalsize = 0;

    await multimediaFiles.forEach((file: any) => {
      totalsize = totalsize + file.size / Math.pow(1024, 2);
    });

    setLoader(true);
    let validateData = await validateEmptyFields(validateInfo);

    if (!validateData || profileImg.length === 0 || !dateValidations) {
      handleFocus();
      setIsValidate(true);
      setLoader(false);
      return true;
    } else if (totalsize > 100) {
      setLoader(false);
      setError("Maximum upload files size should be less than 100 MB");
    } else {
      setError("");
      setLoader(true);

      const data = new FormData();

      const newmultimediaFiles = multimediaFiles.map(
        (file: File) =>
          new File([file], `${+new Date()}_${file.name.replace(/ /g, "_")}`, {
            type: file.type,
          })
      );

      if (mode.isCoupon) {
        data.append("coupon", "$free_obituary@2023");
      }
      data.append("tributeImage", profileImg[0]);

      await newmultimediaFiles.forEach((file: any) => {
        data.append("files", file);
      });

      let contentData = {
        ...createObituaryData,
      };
      data.append("content", JSON.stringify(contentData));
      let response: any = await createObituaryService(data);

      setLoader(false);
      if (response.code === 200) {
        setError("");
        setObituaryData({
          data: createObituaryData,
          multimediaFiles: newmultimediaFiles,
          paymentInfo: response.body,
          profileImg: profileImg,
        });
        handleChange(event, "2");
      } else {
        setError(response.message);
      }
      setLoader(false);
    }
  };

  const filesPreviewer = useMemo(() => {
    return (
      <FilePreview
        filesList={multimediaFiles}
        isDelete={true}
        updateFilesCb={updateUploadedFiles}
      />
    );
  }, [multimediaFiles]);

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          columnSpacing={{ md: 3 }}
          rowSpacing={{ xs: 3 }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                WHO IS THIS PAGE FOR ? *
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={"petName"}
                  label="Name of the Pet *"
                  value={createObituaryData.petName}
                  onChange={handleOnChange}
                  error={isValidate}
                  helperText={"Please Enter Name"}
                  nameRef={petNameRef}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className={"main-text"}
                  sx={{ textTransform: "uppercase" }}
                >
                  DATE OF Birth / Gotcha Day *
                </Typography>
                <DatePicker
                  nameRef={dateOfBirthRef}
                  name={"dob"}
                  value={createObituaryData.dob}
                  error={
                    (isValidate && createObituaryData.dob === "") ||
                    dateValidation !== ""
                  }
                  helperText={dateValidation || "Please Enter Date Of Birth"}
                  onChange={(newValue: SetStateAction<dayjs.Dayjs | null>) => {
                    handleOnDateChange("dob", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className={"main-text"}
                  sx={{ textTransform: "uppercase" }}
                >
                  DATE OF DEATH *
                </Typography>
                <DatePicker
                  nameRef={dateOfDeathRef}
                  name={"dod"}
                  value={createObituaryData.dod}
                  error={
                    (isValidate && createObituaryData.dod === "") ||
                    dateValidation !== ""
                  }
                  helperText={dateValidation || "Please Enter Date Of Death"}
                  onChange={(newValue: SetStateAction<dayjs.Dayjs | null>) => {
                    handleOnDateChange("dod", newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                Where did they LIVE IN MOST RECENTLY ? *
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  label={"Country *"}
                  name={"country"}
                  nameRef={countryRef}
                  value={createObituaryData.country}
                  error={isValidate}
                  helperText={"Please Select Country From List"}
                  optionsList={countryList}
                  onInputChange={(_e: any, value: string) => {
                    getStates(value);
                    setCreateObituaryData((prevState) => {
                      return {
                        ...prevState,
                        country: value,
                        state: "",
                        city: "",
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoComplete
                  label={"State *"}
                  name={"state"}
                  nameRef={stateRef}
                  value={createObituaryData.state}
                  error={isValidate}
                  helperText={"Please Select State From List"}
                  optionsList={statesList}
                  onInputChange={(e: any, value: string) => {
                    getCity(value);
                    setCreateObituaryData((prevState) => {
                      return { ...prevState, state: value, city: "" };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutoComplete
                  label={"City *"}
                  name={"city"}
                  nameRef={cityRef}
                  error={isValidate}
                  helperText={"Please Select City From List"}
                  value={createObituaryData.city}
                  optionsList={cityLists}
                  onInputChange={(_e: any, value: string) => {
                    setCreateObituaryData((prevState) => {
                      return { ...prevState, city: value };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="row"
              columnSpacing={{ md: 2 }}
              rowSpacing={{ xs: 2 }}
              className={classes.container}
            >
              <Grid
                item
                xs={12}
                className={"main-text"}
                sx={{ textTransform: "uppercase" }}
              >
                About you
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={"publisherFirstName"}
                  label="First Name *"
                  value={createObituaryData.publisherFirstName}
                  error={isValidate}
                  helperText={"Please Enter First Name"}
                  onChange={handleOnChange}
                  nameRef={yourFirstNameRef}
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  name={"publisherLastName"}
                  label="Last Name *"
                  value={createObituaryData.publisherLastName}
                  error={isValidate}
                  helperText={"Please Enter Last Name"}
                  onChange={handleOnChange}
                  nameRef={yourLastNameRef}
                />
              </Grid>

              <Grid item xs={12}>
                <EmailInput
                  name={"email"}
                  label="E-mail *"
                  value={createObituaryData.email}
                  error={isValidate}
                  onChange={handleOnChange}
                  nameRef={emailRef}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <AutoComplete
                  name={"publisherRelation"}
                  label="You are the pet’s"
                  value={createObituaryData.publisherRelation || ""}
                  optionsList={relationShips}
                  onChange={(e: any, value: string) => {
                    setCreateObituaryData((prevState) => {
                      return { ...prevState, publisherRelation: value };
                    });
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.container}>
            <Typography
              className={"main-text"}
              sx={{
                paddingTop: "15px",
                paddingBottom: "5px",
                textTransform: "uppercase",
              }}
            >
              Cover Photo of the Pet *
            </Typography>
            <Box sx={{ position: "relative" }}>
              {profileImg.length === 0 ? (
                <>
                  <Box
                    className="main-text"
                    sx={{
                      color: "#e16f6f!important",
                      fontSize: "12px !important",
                      paddingBottom: "8px",
                    }}
                  >
                    * Recommended resolution 200 x 200
                  </Box>
                  <FileUpload
                    accept={profileExtension}
                    filesList={profileImg}
                    profile={true}
                    multiple={false}
                    updateFilesCb={updateProfile}
                    fileButtonRef={profileImgRef}
                    error={isValidate}
                    helperText="Please Add Cover Photo Of the Person"
                  />
                </>
              ) : (
                <FilePreview
                  filesList={profileImg}
                  profile={true}
                  isDelete={true}
                  updateFilesCb={removeProfile}
                />
              )}
            </Box>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ position: "relative", marginTop: "23px" }}
            >
              <FileUpload
                accept={mutipleFileSupport}
                multiple={false}
                filesList={multimediaFiles}
                updateFilesCb={updateUploadedFiles}
              />
            </Grid>
          </Grid>
          {multimediaFiles?.length > 0 && (
            <Grid item xs={12} md={12}>
              {filesPreviewer}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              className={"main-text"}
              sx={{ textTransform: "uppercase" }}
              pb={1}
            >
              Write an obituary *
            </Typography>
            <TextInput
              rows={6}
              name="description"
              value={createObituaryData.description}
              multiline={true}
              maxLength={2000}
              error={isValidate}
              helperText={"Please Enter Description"}
              onChange={handleOnChange}
              nameRef={descriptionRef}
            />
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12} className="main-text Mui-error">
            {error}
          </Grid>
        )}
      </Container>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.processToReviewContainer}
      >
        <Grid item xs={6} sm={4} md={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ textTransform: "capitalize" }}
            className={classes.processPreviewButton}
          >
            Proceed to Pay
          </Button>
        </Grid>
      </Grid>

      <ModalComponent
        openModal={openModal?.open}
        modalWidth={300}
        closeModal={() => {
          setOpenModal({
            ...openModal,
            open: false,
          });
        }}
        closeIcon
        content={
          <ImageCroper
            image={openModal?.image}
            setCropImage={(cropImage: any) => {
              setOpenModal({
                ...openModal,
                open: false,
              });
              setProfileImg(cropImage);
            }}
          />
        }
      />

      <Loader loader={loader} message={progressBar} size="3.5rem" />
    </>
  );
};

export default CreateObituary;
