import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Box, Grid, Link, ListItem } from "@mui/material";
import { Container } from "@mui/system";
import { memo } from "react";
import AllBlogList from "./blogs";

const Blogs = () => {
  // const blogList = Array.from({length: 25}, (_, index) => ({
  //   href: `blog-${index + 1}.html`,
  // }));

  return (
    <Container maxWidth="lg" sx={{paddingBottom: "20px"}}>
      <Box className="title" pb={5}>
        Blogs
      </Box>

      <Grid container direction="row" alignItems="center" spacing={2}>
        {AllBlogList?.map((path: string, index: number) => {
          return (
            <Grid xs={6} sm={4} key={`${path}-${index}`} p={1}>
              <ListItem disablePadding>
                <RadioButtonCheckedIcon
                  sx={{color: "#6C59B7", fontSize: "18px", mr: 1}}
                />
                <Link
                  href={`https://wewillremember.com/blogs/${path}`}
                  target="_blank"
                  underline="hover"
                  className={"sub-title"}
                  sx={{
                    fontSize: "18px",
                    color: "#232227 !important",
                    width: "fit-content !important",
                  }}
                >
                  {`Blog - ${index + 1}`}
                </Link>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default memo(Blogs);
