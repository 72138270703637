import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { regIsSpace } from "../../utils/helpers/regEx";

interface AutoSelectProps {
  name: string;
  label?: string;
  value: string;
  optionsList: any[];
  onChange?: any;
  onInputChange?: any;
  error?: boolean;
  helperText?: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
  nameRef?: any;
}
const AutoComplete = (props: AutoSelectProps) => {
  const {
    name,
    label,
    value,
    optionsList,
    onChange,
    error,
    helperText,
    onInputChange,
    nameRef,
  } = props;

  const newValue = error && regIsSpace.test(value) ? value.trimStart() : value;

  return (
    <>
      <InputLabel
        sx={{
          fontSize: "16px",
          color: "#23222799",
          fontWeight: 400,
          fontFamily: "Lato !important",
          paddingBottom: "8px",
        }}
      >
        {label}
      </InputLabel>
      <Autocomplete
        fullWidth
        id="autocomplete"
        freeSolo
        openOnFocus
        selectOnFocus
        value={value}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={optionsList}
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            value={newValue}
            inputRef={nameRef}
            name={name}
            error={error && newValue === ""}
            helperText={error && newValue === "" && helperText}
            sx={{
              "& .MuiFormHelperText-root": { marginLeft: "2px !important" },
            }}
          />
        )}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            height: "49px",
            padding: "2px 10px",
          },
        }}
      />
    </>
  );
};

export default AutoComplete;
