import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { regIsSpace } from "../../utils/helpers/regEx";

const CustomTextField = styled(TextField)({
  ".MuiOutlinedInput-input": {
    height: "45px",
    padding: "2px 10px",
    borderColor: "#E4E4E4 !important",
    backgroundColor: "#FFF !important",
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    appearance: "textfield" /* for Firefox */,
    "-moz-appearance": "textfield" /* for Firefox */,
  },
  "& .MuiFormHelperText-root": { marginLeft: "2px !important" },
});

const TextInput = (props: any) => {
  const {
    name,
    type = "text",
    label,
    placeholder,
    value,
    onChange,
    error,
    helperText,
    rows,
    multiline,
    maxLength,
    minLength,
    autoComplete,
    isMatch,
    nameRef,
    inputMode,
    disable
  } = props;

  const isSpace = error && regIsSpace.test(value);
  const newValue = isSpace ? value.trimStart() : value;
  const isError = (error && newValue === "") || isMatch;

  return (
    <>
      {label && (
        <InputLabel
          className="main-text"
          sx={{
            color: "#23222799 !important",
            paddingBottom: "8px",
          }}
        >
          {label}
        </InputLabel>
      )}

      <CustomTextField
        name={name}
        type={type}
        value={newValue}
        placeholder={placeholder}
        fullWidth
        error={isError}
        inputProps={{ maxLength, minLength }}
        helperText={isError && helperText}
        variant="outlined"
        autoComplete={autoComplete}
        rows={rows}
        multiline={multiline}
        onChange={onChange}
        inputRef={nameRef}
        inputMode={inputMode}
        disabled={disable}
      />
    </>
  );
};

export default TextInput;
