import { useLayoutEffect, useState } from "react";
import { regEmail } from "./regEx";

export const formatDate = (date: any, separator?: string) => {
  let newDate = new Date(date);

  let month = "" + (newDate.getMonth() + 1);
  let day = "" + newDate.getDate();
  let year = newDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join(separator || "/");
};

export const formatMultimediaFiles = (multimediaFiles: any) => {
  return multimediaFiles.map(
    (file: any) =>
      new File([file], `${+new Date()}_${file.name.replace(/ /g, "_")}`, {
        type: file.type,
      })
  );
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

//Check Required Validation for empty fields

export const validateEmptyFields = async (items: any) => {
  let validate: boolean = true;

  Object.keys(items).forEach((itme: any) => {
    if (
      typeof items[itme] !== "object" &&
      (items[itme] === undefined ||
        items[itme] === "" ||
        items[itme] === null ||
        items[itme].trim() === "")
    ) {
      validate = false;
    } else if (itme === "email" && !regEmail.test(items[itme])) {
      validate = false;
    } else if (typeof items[itme] === "object") {
      // First level Nested Object

      Object.keys(items[itme]).forEach((chlidItem: any) => {
        if (
          typeof items[itme][chlidItem] !== "object" &&
          (items[itme][chlidItem] === undefined ||
            items[itme][chlidItem] === "" ||
            items[itme][chlidItem] === null ||
            items[itme][chlidItem].trim() === "")
        ) {
          validate = false;
        }
      });
    }
  });
  return validate;
};

export const obituariesPagination = (totalCount: number) => {
  const paginationCount: any[] = [];

  if (totalCount > 8) {
    for (let i = 1; i < totalCount + 1; i++) {
      if (i % 9 === 0) {
        const start =
          paginationCount.length > 0
            ? paginationCount[paginationCount.length - 1].end
            : 0;

        const end = i;
        paginationCount.push({ start: start + 1, end: end });
      }
    }

    if (totalCount > paginationCount[paginationCount.length - 1]?.end) {
      const start = paginationCount[paginationCount.length - 1].end;
      const end = totalCount;
      paginationCount.push({ start: start + 1, end: end });
    }
  } else {
    paginationCount.push({ start: totalCount > 0 ? 1 : 0, end: totalCount });
  }

  return paginationCount;
};
var EmbedVideo = function (props: any) {
  return (
    <div
      key={props.id}
      dangerouslySetInnerHTML={{
        __html: `
       <video
         muted
         autoplay="off"
         playsInline
         src="${props.src}"
         height=250px
         width=100%
         controls
         disablePictureInPicture 
         controlsList="nodownload"
       />,
     `,
      }}
    ></div>
  );
};
export const obituariesImagePreviewer = (
  isImage: string,
  path: string,
  index: number,
  id?: number
) => {
  if (isImage?.length || isImage !== null) {
    return isImage.toLowerCase() === "image" ? (
      <img
        src={path}
        alt={`file preview ${index}`}
        key={`${index}_${id}`}
        className={"image-previewer"}
      />
    ) : isImage.toLowerCase() === "application" ||
      isImage.toLowerCase() === "pdf" ? (
      <iframe
        src={path ? path : ""}
        title={`file preview ${index}`}
        width="100%"
        height="100%"
        allow="autoplay=off"
        autoSave="off"
        key={`${index}_${id}`}
        className="image-previewer"
        frameBorder="0"
        allowFullScreen
      />
    ) : (
      <EmbedVideo id={`${index}_${id}`} src={path} />
    );
  }
};

export const mailToPrompt = (email: string) => {
  return (
    <a href={`mailto:${email}`} style={{ color: "#6c59b7 !important" }}>
      <span style={{ color: "#6c59b7 !important" }}>{email}</span>
    </a>
  );
};
