import loadingImage from "../../assests/images/Loading.svg";

const VideoPlayer = (props: any) => {
  const { vimeoPath } = props;

  const getVideoIdFromVimeoUrl = (url: any) => {
    const index = url.lastIndexOf("/");
    return url.substr(index);
  };

  return (
    <div className="container">
      <iframe
        title="Tribute video"
        className="responsive-iframe"
        src={
          vimeoPath
            ? `https://player.vimeo.com/video${getVideoIdFromVimeoUrl(
                vimeoPath
              )}?h=4586b7d03e&amp;badge=0&amp;autoplay=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
            : loadingImage
        }
        frameBorder="0"
        // allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
