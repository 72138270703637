import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./pages/layout";
import { Loader } from "./widgets/loader";

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader loader={true} />}>
        <Layout />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
