import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

interface loaderProps {
  loader: boolean;
  size?: string;
  NoBackdrop?: boolean;
  thickness?: number;
  message?: string;
  disableShrink?: boolean;
  timer?: any;
}
export const Loader = (props: loaderProps) => {
  const {
    loader,
    NoBackdrop,
    size = "2rem",
    thickness,
    message,
    timer = 0,
    disableShrink,
  } = props;
  const circularProgress = () => {
    return (
      <>
        <CircularProgress
          size={size}
          disableShrink={disableShrink}
          value={parseInt(timer)}
          thickness={thickness}
          sx={{
            color: "#6C59B7 !important",
          }}
        />
        {message && (
          <Box
            className="main-text"
            sx={{ position: "absolute", color: "#fff", paddingTop: "10px" }}
          >
            {`${message} %`}
          </Box>
        )}
      </>
    );
  };

  return loader && NoBackdrop ? (
    circularProgress()
  ) : (
    <>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        {circularProgress()}
      </Backdrop>
    </>
  );
};
