import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { memo, useState } from "react";
import { useWindowSize } from "../../utils/helpers/helper";

const useStyles = makeStyles({
  description: {
    columnCount: 2,
    columnGap: "30px !important",
    textAlign: "justify",
    wordWrap: "break-word",
    "@media (max-width: 600px)": {
      columnCount: "unset",
      columnGap: "0 !important",
    },
  },
  readMore: {
    paddingLeft: "6px !important",
    color: "#6C59B7 !important",
  },
});

interface DescriptionProps {
  description: string;
}
const Description = (props: DescriptionProps) => {
  const { description } = props;
  const classes = useStyles();
  const [readMore, setReadMore] = useState(false);
  const [width] = useWindowSize();
  const showMoreLength = width < 768 ? 600 : 99999;

  return (
    <Grid container direction="row" alignItems="center" py={2}>
      <Grid item xs={12} md={12} className={`main-text ${classes.description}`}>
        {readMore ? description : description.substring(0, showMoreLength)}
        {description.length > showMoreLength && (
          <Box
            component="span"
            onClick={() => {
              setReadMore(!readMore);
            }}
            className={classes.readMore}
          >
            {readMore ? "Show less" : "Show more . . ."}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default memo(Description);
