import { Box, Grid } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { forwardRef, memo, useImperativeHandle, useState } from "react";
import { validateEmptyFields } from "../../../utils/helpers/helper";
import EmailInput from "../../../widgets/emailInput";
import { Loader } from "../../../widgets/loader";
import TextInput from "../../../widgets/textInput";

const CARD_EXP_OPTIONS = {
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Lato",
      fontSize: "16px",
      letterSpacing: "0.2rem",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#495057",
      },
      "::placeholder": {
        color: "#7e7581",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#495057",
    },
  },
};

const CARD_NUM_OPTIONS = {
  placeholder: "xxxx xxxx xxxx xxxx",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Lato",
      fontSize: "16px",
      border: "1px soild gray",
      letterSpacing: "0.2rem",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#495057",
      },
      "::placeholder": {
        color: "#7e7581",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#495057",
    },
  },
};

const CARD_CVV_OPTIONS = {
  placeholder: "_ _ _ ",
  max: 3,
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Lato",
      fontSize: "16px",
      letterSpacing: "0.2rem",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#495057",
      },
      "::placeholder": {
        color: "#7e7581",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#495057",
    },
  },
};

const PaymentLabel = (props: any) => (
  <>
    <Box
      sx={{
        color: "rgba(35, 34, 39, 0.6) !important",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "140%",
        paddingTop: "10px !important",
        paddingBottom: "8px !important",
        width: "100% !important",
      }}
    >
      {props.label}
    </Box>
    <Box
      sx={{
        border: "1px solid #c4c4c4!important",
        borderRadius: "4px",
        padding: "13.5px 10px",
        bgcolor: "#fff",
      }}
    >
      {props.children}
    </Box>
  </>
);

const CheckoutForm = forwardRef((props: any, ref) => {
  const { setError, obituaryId, makePayment } = props;

  const stripe = useStripe();

  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const [accountData, setAccountData] = useState({
    email: "",
    cardName: "",
    zipCode: "",
  });

  useImperativeHandle(ref, () => ({
    log() {
      handleSubmit();
    },
  }));

  const handleSubmit = async () => {
    setLoader(true);
    let validateData = await validateEmptyFields(accountData);

    if (!validateData || accountData.zipCode.length !== 5) {
      setLoader(false);
      setIsValidate(true);
      return true;
    }
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements?.getElement(CardNumberElement);

    const cardExpiryElement = elements?.getElement(CardExpiryElement);

    const cardCvcElement = elements?.getElement(CardCvcElement);

    if (cardNumberElement && cardExpiryElement && cardCvcElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });
      setLoader(false);
      if (error) {
        setError(error.message);
      } else {
        setError("");
        makePayment(paymentMethod, accountData);
      }
    } else {
      setLoader(false);
    }
  };

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    setAccountData({
      ...accountData,
      [name]: value,
    });
  };

  return (
    <>
      <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={12}>
          <EmailInput
            name="email"
            label="Email"
            placeholder="Enter an email"
            value={accountData.email}
            error={isValidate}
            onChange={handleOnChange}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <PaymentLabel label={"Card Number"}>
            <CardNumberElement options={CARD_NUM_OPTIONS} />
          </PaymentLabel>
        </Grid>

        <Grid item xs={6} md={6}>
          <PaymentLabel label={"Expiration Date"}>
            <CardExpiryElement options={CARD_EXP_OPTIONS} />
          </PaymentLabel>
        </Grid>

        <Grid item xs={6} md={6}>
          <PaymentLabel label={"Security Code"}>
            <CardCvcElement options={CARD_CVV_OPTIONS} />
          </PaymentLabel>
        </Grid>

        <Grid item xs={8} md={8}>
          <TextInput
            type="text"
            name="cardName"
            label="Name on Card"
            placeholder="Enter Name on Card"
            helperText={"Enter Name on Card"}
            maxLength={30}
            error={isValidate}
            onChange={(e: any) => {
              const { value, name } = e.target;
              let cardName = "";
              if (/[A-Za-z]/.test(value)) {
                cardName = value;
              }
              setAccountData((prevState) => {
                return { ...prevState, [name]: cardName };
              });
            }}
            value={accountData.cardName}
          />
        </Grid>

        <Grid item xs={4} md={4}>
          <TextInput
            type="number"
            inputMode="numeric"
            name="zipCode"
            label="ZIP code"
            placeholder="_ _ _ _ _"
            helperText={"Enter ZIP code"}
            maxLength={5}
            minLength={5}
            value={accountData.zipCode}
            error={isValidate}
            isMatch={isValidate && accountData.zipCode.length !== 5}
            onChange={(e: any) => {
              const { value, name } = e.target;
              setAccountData((prevState) => {
                return { ...prevState, [name]: value?.slice(0, 5) };
              });
            }}
          />
        </Grid>
      </Grid>
      <Loader loader={loader} />
    </>
  );
});
export default memo(CheckoutForm);
