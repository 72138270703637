import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../components/logo";
import CreateObituary from "./create";
import { initalState, paymentInitialState } from "./create/type";
import StripePayment from "./payment/stripe-payment";
import ShareObituary from "./share";

const useStyles = makeStyles({
  heading: {
    textAlign: "left",
    fontSize: "48px",
    padding: "16px",
    "@media (max-width: 768px)": { fontSize: "30px" },
    "@media (max-width: 426px)": { display: "none" },
  },
  tabs: {
    marginBottom: "20px !important",
    "& .MuiTabs-indicator": {
      background: "#6C59B7 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#6C59B7 !important",
    },
  },
  label: {
    color: "#232227 !important",
    padding: "0 30px !important",
    fontFamily: "Lato !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    "@media (max-width: 768px)": { padding: "0 !important" },
    "@media (max-width: 425px)": { fontSize: "14px !important" },
  },
});

const ObituaryTabPanel = () => {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const location = useLocation();
  const [obituaryData, setObituaryData] = useState({
    data: initalState,
    profileImg: [],
    multimediaFiles: [],
    paymentInfo: paymentInitialState,
  });

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const coupon = searchParams.get("fbclid");

  const [mode, setMode] = useState({
    edit: false,
    payment: false,
    isCoupon: coupon ? true : false,
  });

  useEffect(() => {
    if (location.pathname.includes("obituary-create")) {
      setValue("1");
    } else if (location.pathname.includes("obituary-payment")) {
      setValue("2");
    } else if (location.pathname.includes("obituary-share")) {
      setValue("3");
    } else if (location.pathname.includes("addMemories-payment")) {
      setValue("4");
    }

    return () => {};
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "1") {
      navigate(`/obituary-create`);
    } else if (newValue === "2") {
      navigate(`/obituary-payment`);
    }
    setValue(newValue);
  };

  const TabLabel = (label: string, tabValue: string) => {
    return (
      <Typography
        key={tabValue}
        component="span"
        fontSize="medium"
        sx={{ color: value === tabValue ? "#000" : "#636668" }}
      >
        {label}
      </Typography>
    );
  };

  const obituaryBreadcrumbs =
    value === "4"
      ? [TabLabel("Add Memories", "0"), TabLabel("Payment", "4")]
      : [
          TabLabel("Create", "1"),
          TabLabel("Payment", "2"),
          TabLabel("Share", "3"),
        ];

  return (
    <Box
      sx={{
        minHeight: `calc(100vh - 132px)`,
      }}
      bgcolor={["2", "4"].includes(value) ? "rgb(239,244,246)" : "#fff"}
    >
      <Container maxWidth="lg" disableGutters={true}>
        {/* <Box className={`h2 ${classes.heading}`}>Obituary Creation</Box> */}

        <Grid container direction="row">
          <Grid item xs={12} px={3} mt={5}>
            <Logo />
          </Grid>
          <Grid item xs={12} px={3} my={4}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="medium" />}
              aria-label="breadcrumb"
            >
              {obituaryBreadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            {value === "1" && (
              <CreateObituary
                obituaryData={obituaryData}
                setObituaryData={setObituaryData}
                handleChange={handleChange}
                value={value}
                mode={mode}
                setMode={setMode}
              />
            )}

            {(value === "2" || value === "4") && (
              <StripePayment
                setMode={setMode}
                mode={mode}
                obituaryData={obituaryData}
                handleChange={handleChange}
                type={value === "4" ? "paymentByPass" : ""}
              />
            )}
            {value === "3" && (
              <ShareObituary
                obituaryData={obituaryData}
                handleChange={handleChange}
                setObituaryData={setObituaryData}
              />
            )}
          </Grid>
        </Grid>

        {/* <Tabs
        value={value}
        onChange={handleChange}
        centered
        className={classes.tabs}
      >
        <Tab
          value="1"
          disabled={true}
          label="Create"
          className={classes.label}
        />
        <Tab
          value="2"
          disabled={!mode.edit || value === "3" || mode.payment}
          label="Preview"
          className={classes.label}
        /> 
        <Tab value="2" label="Pay" disabled={true} className={classes.label} />
        <Tab
          value="3"
          label="SHARE"
          disabled={true}
          className={classes.label}
        />
  </Tabs> */}
      </Container>
    </Box>
  );
};

export default memo(ObituaryTabPanel);
